import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { postService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Poll = function () {
  const singleCollectionName = 'Post'
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.subGetOneMutation(
    singleQueryKey,
    params.id,
    params.optionId,
    postService.getPollOption
  )
  const updateMutation = reactQuery.subUpdateMutation(
    postService.updatePollOption,
    params.id,
    params.optionId,
    singleCollectionName,
    singleQueryKey
  )
  const deleteMutation = reactQuery.subDeleteMutation(
    postService.deletePollOption,
    params.id,
    params.optionId,
    singleCollectionName,
    singleQueryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate.Option}`,
          buttonColor: 'primary',
          onSubmit: (args) => updateMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate.Option}`,
            inputs: [
              {
                name: 'option',
                label: 'Option',
                type: 'text',
                value: getQuery.data?.option,
                required: true,
              },
              {
                name: 'optionAR',
                label: 'Arabic Option',
                type: 'text',
                value: getQuery.data?.optionAR,
                required: true,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate.Option}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate.Option}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate.Option}`,
        },
      ]}
      title="Option"
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Poll
