import axios from '../_helpers/axios'

const route = 'posts'
const create = async (createBody) => axios.post(`v1/${route}`, createBody)
const query = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const get = async (id) => axios.get(`v1/${route}/${id}`)
const update = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const remove = async (id) => axios.delete(`v1/${route}/${id}`)

const createPollOption = async (postId, createBody) =>
  axios.post(`v1/${route}/manage-vote-poll/${postId}`, createBody)
const getPollOption = async (postId, optionId) =>
  axios.get(`v1/${route}/manage-vote-poll/${postId}/${optionId}`)
const updatePollOption = async (postId, optionId, updateBody) =>
  axios.patch(`v1/${route}/manage-vote-poll/${postId}/${optionId}`, updateBody)
const deletePollOption = async (postId, optionId) =>
  axios.delete(`v1/${route}/manage-vote-poll/${postId}/${optionId}`)

const createComment = async (postId, createBody) =>
  axios.post(`v1/${route}/comment-post/${postId}`, createBody)
const getComment = async (postId, commentId) =>
  axios.get(`v1/${route}/comment-post/${postId}?commentId=${commentId}`)
const updateComment = async (postId, commentId, updateBody) =>
  axios.patch(
    `v1/${route}/comment-post/${postId}?commentId=${commentId}`,
    updateBody
  )
const deleteComment = async (postId, commentId) =>
  axios.delete(`v1/${route}/comment-post/${postId}?commentId=${commentId}`)

const createReply = async (postId, commentId, createBody) =>
  axios.post(`v1/${route}/reply-comment/${postId}/${commentId}`, createBody)
const getReply = async (postId, commentId, replyId) =>
  axios.get(
    `v1/${route}/reply-comment/${postId}/${commentId}?replyId=${replyId}`
  )
const updateReply = async (postId, commentId, replyId, updateBody) =>
  axios.patch(
    `v1/${route}/reply-comment/${postId}/${commentId}?replyId=${replyId}`,
    updateBody
  )
const deleteReply = async (postId, commentId, replyId) =>
  axios.delete(
    `v1/${route}/reply-comment/${postId}/${commentId}?replyId=${replyId}`
  )
export default {
  create,
  query,
  get,
  update,
  remove,
  createPollOption,
  getPollOption,
  updatePollOption,
  deletePollOption,
  createComment,
  getComment,
  updateComment,
  deleteComment,
  createReply,
  getReply,
  updateReply,
  deleteReply,
}
