module.exports = {
  Ad: 'Ad',
  Add: 'Add',
  Ads: 'Ads',
  Advertisments: 'Advertisments',
  Amount: 'Amount',
  'Arabic Description': 'Arabic Description',
  'Arabic Message': 'Arabic Message',
  'Arabic Name': 'Arabic Name',
  'Arabic Option': 'Arabic Option',
  'Arabic Quote': 'Arabic Quote',
  'Arabic Quotee': 'Arabic Quotee',
  'Arabic Title': 'Arabic Title',
  'Are you sure you want to create this':
    'Are you sure you want to create this',
  'Are you sure you want to delete this':
    'Are you sure you want to delete this',
  'Are you sure you want to manage this':
    'Are you sure you want to manage this',
  'Audio File': 'Audio File',
  'Birth Date': 'Birth Date',
  Brand: 'Brand',
  'CTV Organization Admin Panel': 'CTV Organization Admin Panel',
  Cancel: 'Cancel',
  'Circle Profile Picture': 'Circle Profile Picture',
  Comment: 'Comment',
  Confirm: 'Confirm',
  'Contact Us': 'Contact Us',
  ContactUs: 'ContactUs',
  Contacted: 'Contacted',
  Country: 'Country',
  'Cover Picture': 'Cover Picture',
  'Created At': 'Created At',
  Currency: 'Currency',
  Date: 'Date',
  'Date Published': 'Date Published',
  Day: 'Day',
  Deadline: 'Deadline',
  Delete: 'Delete',
  Description: 'Description',
  'Description AR': 'Arabic Description',
  'Donation Goal': 'Donation Goal',
  'Donation Type': 'Donation Type',
  Donations: 'Donations',
  Duration: 'Duration',
  Email: 'Email',
  'End Hour': 'End Hour',
  'End Minute': 'End Minute',
  'End Time': 'End Time',
  'Episodes Count': 'Episodes Count',
  Error: 'Error',
  'Export CSV': 'Export CSV',
  'Export PDF': 'Export PDF',
  Filter: 'Filter',
  Filters: 'Filters',
  'First Run': 'First Run',
  Friday: 'Friday',
  Gender: 'Gender',
  Generate: 'Generate',
  Home: 'Home',
  Last4: 'Last4',
  'Loading...': 'Loading...',
  'Logged in': 'Logged in',
  Login: 'Login',
  Logs: 'Logs',
  Manage: 'Manage',
  Media: 'Media',
  Message: 'Message',
  'Message AR': 'Arabic Message',
  'Mins To Read': 'Mins To Read',
  'Missing Information': 'Missing Information',
  Monday: 'Monday',
  Month: 'Month',
  Name: 'Name',
  'Name AR': 'Arabic Name',
  News: 'News',
  No: 'No',
  Notification: 'Notifcation',
  Notifications: 'Notifcations',
  Option: 'Option',
  'Option AR': 'Arabic Option',
  Options: 'Options',
  Other: 'Other',
  Password: 'Password',
  'Phone Number': 'Phone Number',
  Post: 'Post',
  Posts: 'Posts',
  Priority: 'Priority',
  'Profile Picture': 'Profile Picture',
  Quote: 'Quote',
  'Quote AR': 'Arabic Quote',
  'Quote of church fathers': 'Quote of church fathers',
  Quotee: 'Quotee',
  'Quotee AR': 'Arabic Quotee',
  Reply: 'Reply',
  Reset: 'Reset',
  Saturday: 'Saturday',
  Schedule: 'Schedule',
  Schedules: 'Schedules',
  Server: 'Server',
  Show: 'Show',
  'Show Name AR': 'Arabic Show Name',
  Shows: 'Shows',
  'Start Hour': 'Start Hour',
  'Start Minute': 'Start Minute',
  'Start Time': 'Start Time',
  Status: 'Status',
  Submit: 'Submit',
  Subscribers: 'Subscribers',
  'Subscribers Count': 'Subscribers Count',
  Sunday: 'Sunday',
  'Thumbnail Picture': 'Thumbnail Picture',
  Thursday: 'Thursday',
  Title: 'Title',
  'Title AR': 'Arabic Title',
  Tuesday: 'Tuesday',
  Type: 'Type',
  Update: 'Update',
  'Updated At': 'Updated At',
  'Updated By Name': 'Updated By Name',
  'Updated Keys': 'Updated Keys',
  User: 'User',
  'User Name': 'User Name',
  Users: 'Users',
  Verified: 'Verified',
  'Verse of the day': 'Verse of the day',
  Video: 'Video',
  'Video File': 'Video File',
  'Video Type': 'Video Type',
  Videos: 'Videos',
  Wednesday: 'Wednesday',
  Year: 'Year',
  Yes: 'Yes',
  'Youtube URL': 'Youtube URL',
  added: 'added',
  'can not be empty': 'can not be empty',
  'could not be': 'could not be',
  deleted: 'deleted',
  edited: 'edited',
  generated: 'generated',
  'is invalid': 'is invalid',
  'is required': 'is required',
  'password must be of minimum length 8 and contain at least 1 letter and 1 number':
    'password must be of minimum length 8 and contain at least 1 letter and 1 number',
  successfully: 'successfully',
}
