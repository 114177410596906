import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { postService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Posts = function ({ showSlug, showName, showNameAR, showPicture }) {
  const collectionName = 'Posts'
  const singleCollectionName = 'Post'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {
    ...(showSlug && { showSlug }),
  }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, postService.query)
  const createMutation = reactQuery.createMutation(
    postService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                value: queryParams.type,
                options: types.postTypes.map((postType) => ({
                  label: stringUtils.camelCase(postType),
                  value: postType,
                })),
                required: true,
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { thumbnailPicture, media, mediaFile, poll } = args
            delete args.thumbnailPicture
            delete args.media
            delete args.mediaFile
            delete args.donationType
            delete args.poll
            const formData = objectUtils.getFormData({
              ...args,
              showSlug,
              showName,
              showNameAR,
              showPicture,
            })
            if (thumbnailPicture && thumbnailPicture.length)
              Object.keys(thumbnailPicture).forEach((key) =>
                formData.append('thumbnailPicture', thumbnailPicture[key])
              )
            if (mediaFile && mediaFile.length)
              Object.keys(mediaFile).forEach((key) =>
                formData.append('url', mediaFile[key])
              )
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            if (poll && poll.length)
              poll.forEach((option) =>
                formData.append('poll', JSON.stringify(option))
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                required: true,
              },
              {
                name: 'descriptionAR',
                label: 'Arabic Description',
                type: 'text',
                required: true,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                value: body.type,
                options: types.postTypes.map((postType) => ({
                  label: stringUtils.camelCase(postType),
                  value: postType,
                })),
                required: true,
              },
              ...(body.type === 'images'
                ? [
                    {
                      name: 'media',
                      label: 'Media',
                      type: 'file',
                      accept: 'image/*',
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'audio'
                ? [
                    {
                      name: 'thumbnailPicture',
                      label: 'Thumbnail Picture',
                      type: 'file',
                      accept: 'image/*',
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'audio' || body.type === 'video'
                ? [
                    {
                      name: 'mediaFile',
                      label: `${stringUtils.camelCase(body.type)} File`,
                      type: 'file',
                      accept: `${body.type}/*`,
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'youtube'
                ? [
                    {
                      name: 'url',
                      label: 'Youtube URL',
                      type: 'text',
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'poll'
                ? [
                    {
                      name: 'poll',
                      label: 'Options',
                      type: 'pollTable',
                    },
                    {
                      name: 'pollDeadline',
                      label: 'Deadline',
                      type: 'date',
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'donation'
                ? [
                    {
                      name: 'donationType',
                      label: 'Donation Type',
                      type: 'select',
                      value: body.donationType,
                      options: types.donationTypes.map((donationType) => ({
                        label: stringUtils.camelCase(donationType),
                        value: donationType,
                      })),
                      required: true,
                    },
                    ...(body.donationType === 'images'
                      ? [
                          {
                            name: 'media',
                            label: 'Media',
                            type: 'file',
                            accept: 'image/*',
                            required: true,
                          },
                        ]
                      : []),
                    ...(body.donationType === 'video'
                      ? [
                          {
                            name: 'mediaFile',
                            label: `Video File`,
                            type: 'file',
                            accept: `video/*`,
                            required: true,
                          },
                        ]
                      : []),
                    ...(body.donationType === 'youtube'
                      ? [
                          {
                            name: 'url',
                            label: 'Youtube URL',
                            type: 'text',
                            required: true,
                          },
                        ]
                      : []),
                    {
                      name: 'donationGoal',
                      label: 'Donation Goal',
                      type: 'number',
                      required: true,
                    },
                  ]
                : []),
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={[
        'showName',
        'showNameAR',
        'showPicture',
        'showType',
        'saved',
        'liked',
        'polled',
      ]}
      navigate={[queryKey, '_id']}
    />
  )
}
export default Posts
