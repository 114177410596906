import axios from '../_helpers/axios'

const route = 'donations'
const query = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
export default {
  query,
}
