import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { donationService } from '../../_services'
import Document from '../Abstract/Document'

const Donations = function ({ userId }) {
  const collectionName = 'Donations'
  const queryKey = collectionName.toLowerCase()
  const defaultQueryParams = {
    ...(userId && { userId }),
    status: 'succeeded',
  }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    donationService.query
  )
  return (
    <Document
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      navigate={!userId ? ['users', 'owner', 'userId'] : []}
      notToShow={['paymentIntentSecret']}
    />
  )
}
export default Donations
