import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translate from '../../../locales/en'

const Boolean = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => true,
  }))
  const [value, setValue] = useState(props.value || false)
  useEffect(() => {
    props.setIsValids((s) => {
      s[props.index] = true
      return s
    })
    props.setIsValid(
      props.isValids.reduce((isAllValid, key, i) => {
        if (i === props.index) return isAllValid && true
        return isAllValid && key
      }, true)
    )
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <FormControlLabel
      style={{ display: 'block' }}
      control={
        <Checkbox
          key={`${props.index}Checkbox`}
          autoFocus={props.index === 0}
          color="primary"
          checked={value}
          onChange={(event) => setValue(event.target.checked)}
        />
      }
      label={translate[props.label]}
      key={`${props.index}FormControlLabel`}
    />
  )
})
export default Boolean
