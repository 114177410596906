import axios from '../_helpers/axios'

const route = 'notifications'
const create = async (createBody) => axios.post(`v1/${route}`, createBody)
const query = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const get = async (id) => axios.get(`v1/${route}/${id}`)
const update = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const remove = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  create,
  query,
  get,
  update,
  remove,
}
