import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { showService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'
import ContactUss from '../Collections/ContactUss'
import Posts from '../Collections/Posts'
import Schedules from '../Collections/Schedules'
import Videos from '../Collections/Videos'

const Show = function () {
  const collectionName = 'Shows'
  const singleCollectionName = 'Show'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    showService.get
  )
  const updateMutation = reactQuery.updateMutation(
    showService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    showService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <>
      <Document
        actions={[
          {
            buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              const newBody = objectUtils.compare(getQuery.data, args)
              const { profilePicture, coverPicture, circleProfilePicture } =
                newBody
              delete newBody.profilePicture
              delete newBody.coverPicture
              delete newBody.circleProfilePicture
              const formData = objectUtils.getFormData(newBody)
              if (profilePicture && profilePicture.length)
                Object.keys(profilePicture).forEach((key) =>
                  formData.append('profilePicture', profilePicture[key])
                )
              if (coverPicture && coverPicture.length)
                Object.keys(coverPicture).forEach((key) =>
                  formData.append('coverPicture', coverPicture[key])
                )
              if (circleProfilePicture && circleProfilePicture.length)
                Object.keys(circleProfilePicture).forEach((key) =>
                  formData.append(
                    'circleProfilePicture',
                    circleProfilePicture[key]
                  )
                )
              if (
                Object.keys(newBody).length ||
                (profilePicture && profilePicture.length) ||
                (coverPicture && coverPicture.length) ||
                (circleProfilePicture && circleProfilePicture.length)
              )
                return updateMutation.mutate(formData)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Update} ${translate[singleCollectionName]}`,
              inputs: [
                {
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  value: getQuery.data.name,
                },
                {
                  name: 'nameAR',
                  label: 'Arabic Name',
                  type: 'text',
                  value: getQuery.data.nameAR,
                },
                {
                  name: 'description',
                  label: 'Description',
                  type: 'text',
                  value: getQuery.data.description,
                },
                {
                  name: 'type',
                  label: 'Type',
                  type: 'select',
                  value: getQuery.data.type,
                  options: types.showsTypes.map((showsType) => ({
                    label: stringUtils.camelCase(showsType),
                    value: showsType,
                  })),
                },
                {
                  name: 'priority',
                  label: 'Priority',
                  type: 'number',
                  value: getQuery.data.priority,
                },
                {
                  name: 'profilePicture',
                  label: 'Profile Picture',
                  type: 'file',
                  accept: 'image/*',
                },
                {
                  name: 'coverPicture',
                  label: 'Cover Picture',
                  type: 'file',
                  accept: 'image/*',
                },
                {
                  name: 'circleProfilePicture',
                  label: 'Circle Profile Picture',
                  type: 'file',
                  accept: 'image/*',
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
            buttonColor: 'secondary',
            onSubmit: () => deleteMutation.mutate(),
            form: {
              title: `${translate.Delete} ${translate[singleCollectionName]}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
          },
        ]}
        title={singleCollectionName}
        getQuery={getQuery}
        many={false}
      />
      <Schedules
        showId={getQuery.data._id}
        showSlug={getQuery.data.slug}
        showName={getQuery.data.name}
        showNameAR={getQuery.data.nameAR}
        showCircleProfilePicture={getQuery.data.circleProfilePicture}
        showCoverPicture={getQuery.data.coverPicture}
        showType={getQuery.data.type}
      />
      <Videos
        showSlug={getQuery.data.slug}
        showName={getQuery.data.name}
        type="episode"
      />
      <Posts
        showSlug={getQuery.data.slug}
        showName={getQuery.data.name}
        showNameAR={getQuery.data.nameAR}
        showPicture={getQuery.data.circleProfilePicture}
      />
      <ContactUss showId={getQuery.data._id} />
    </>
  )
}
export default Show
