import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { userService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const User = function () {
  const collectionName = 'Users'
  const singleCollectionName = 'User'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    userService.get
  )
  const updateMutation = reactQuery.updateMutation(
    userService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    userService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'email',
                label: 'Email',
                type: 'email',
                value: getQuery.data?.email,
              },
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                value: getQuery.data?.name,
              },
              {
                name: 'birthdate',
                label: 'Birth Date',
                type: 'date',
                value: getQuery.data?.birthdate,
              },
              {
                name: 'gender',
                label: 'Gender',
                type: 'select',
                value: getQuery.data?.gender,
                options: [
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Male',
                    value: 'male',
                  },
                  {
                    label: 'Female',
                    value: 'female',
                  },
                ],
              },
              {
                name: 'country',
                label: 'Country',
                type: 'text',
                value: getQuery.data?.country,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default User
