import React from 'react'

import ImageRender from '../Common/Image'

const Image = (path) =>
  function (rowData) {
    return rowData[path] ? (
      <ImageRender data={path === 'media' ? rowData[path][0] : rowData[path]} />
    ) : (
      ''
    )
  }
export default Image
