import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { newsService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const News = function () {
  const collectionName = 'News'
  const singleCollectionName = 'News'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, newsService.query)
  const createMutation = reactQuery.createMutation(
    newsService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                value: queryParams.type,
                options: types.newsTypes.map((newsType) => ({
                  label: stringUtils.camelCase(newsType),
                  value: newsType,
                })),
                required: true,
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { profilePicture, coverPicture } = args
            delete args.profilePicture
            delete args.coverPicture
            const formData = objectUtils.getFormData(args)
            Object.keys(profilePicture).forEach((key) =>
              formData.append('profilePicture', profilePicture[key])
            )
            Object.keys(coverPicture).forEach((key) =>
              formData.append('coverPicture', coverPicture[key])
            )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'nameAR',
                label: 'Arabic Name',
                type: 'text',
                required: true,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                required: true,
              },
              {
                name: 'descriptionAR',
                label: 'Arabic Description',
                type: 'text',
                required: true,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                options: types.newsTypes.map((newsType) => ({
                  label: stringUtils.camelCase(newsType),
                  value: newsType,
                })),
                required: true,
              },
              {
                name: 'profilePicture',
                label: 'Profile Picture',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
              {
                name: 'coverPicture',
                label: 'Cover Picture',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default News
