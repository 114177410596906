import axios from 'axios'

import localStorageUtils from '../_helpers/localStorageUtils'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL
const route = 'auth'
const login = (body) =>
  axios.post(`v1/${route}/admin-login`, body).then(({ data }) => {
    localStorageUtils.set(
      localStorageUtils.availableKeys.ACCESS_TOKEN,
      data.tokens.access.token
    )
    localStorageUtils.set(
      localStorageUtils.availableKeys.ACCESS_TOKEN_EXPIRY,
      data.tokens.access.expires
    )
    localStorageUtils.set(
      localStorageUtils.availableKeys.REFRESH_TOKEN,
      data.tokens.refresh.token
    )
    window.dispatchEvent(new Event('storage'))
  })
const logout = () => {
  axios
    .post(`v1/${route}/logout`, {
      refreshToken: localStorageUtils.get(
        localStorageUtils.availableKeys.REFRESH_TOKEN
      ),
    })
    .then(() => {
      localStorageUtils.clear()
      window.dispatchEvent(new Event('storage'))
    })
}
export default {
  login,
  logout,
}
