import MaterialTable from '@material-table/core'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translate from '../../../locales/en'
import Text from '../TableInputs/Text'

const PollTable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => true,
  }))
  useEffect(() => {
    props.setIsValids((s) => {
      s[props.index] = true
      return s
    })
    props.setIsValid(
      props.isValids.reduce((isAllValid, key, i) => {
        if (i === props.index) return isAllValid && true
        return isAllValid && key
      }, true)
    )
  }, [])
  const [data, setData] = useState(props.value || [])
  useEffect(() => props.setBody((s) => ({ ...s, [props.name]: data })), [data])
  return (
    <div className="tableComponent">
      <MaterialTable
        title={translate[props.label]}
        columns={[
          {
            title: translate.Option,
            field: 'option',
            editComponent: (props) => (
              <Text
                value={props.value || ''}
                onChange={(v) => props.onChange(v)}
                label="Option"
                required
                index={
                  props.index +
                  1 +
                  (data.length ? Object.keys(data[0]).length : 0) * data.length
                }
              />
            ),
          },
          {
            title: translate['Arabic Option'],
            field: 'optionAR',
            editComponent: (props) => (
              <Text
                value={props.value || ''}
                onChange={(v) => props.onChange(v)}
                label="Arabic Option"
                required
                index={
                  props.index +
                  2 +
                  (data.length ? Object.keys(data[0]).length : 0) * data.length
                }
              />
            ),
          },
        ]}
        data={data}
        style={{
          overflowX: 'hidden',
          width: '100%',
          marginTop: '5px',
          marginBottom: '5px',
        }}
        options={{
          paging: false,
          search: false,
          actionsColumnIndex: -1,
          rowStyle: { fontSize: 14 },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setData([...data, newData])
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const dataUpdate = [...data]
              const index = oldData.tableData.id
              dataUpdate[index] = newData
              setData([...dataUpdate])
              resolve()
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data]
              const index = oldData.tableData.id
              dataDelete.splice(index, 1)
              setData([...dataDelete])
              resolve()
            }),
        }}
      />
    </div>
  )
})
export default PollTable
