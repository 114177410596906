import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { homeService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Homes = function () {
  const collectionName = 'Homes'
  const singleCollectionName = 'Home'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, homeService.query)
  const createMutation = reactQuery.createMutation(
    homeService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                value: queryParams.type,
                options: [
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Quote of church fathers',
                    value: 'QOCF',
                  },
                  {
                    label: 'Verse of the day',
                    value: 'VOD',
                  },
                ],
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'quote',
                label: 'Quote',
                type: 'text',
                required: true,
              },
              {
                name: 'quoteAR',
                label: 'Arabic Quote',
                type: 'text',
                required: true,
              },
              {
                name: 'quotee',
                label: 'Quotee',
                type: 'text',
                required: true,
              },
              {
                name: 'quoteeAR',
                label: 'Arabic Quotee',
                type: 'text',
                required: true,
              },
              {
                name: 'date',
                label: 'Date',
                type: 'date',
                required: true,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                options: [
                  {
                    label: 'Quote of church fathers',
                    value: 'QOCF',
                  },
                  {
                    label: 'Verse of the day',
                    value: 'VOD',
                  },
                ],
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Homes
