import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import React, { createRef, useEffect, useMemo, useState } from 'react'

import translate from '../../locales/en'
import Guard from '../Guards/Guard'
import inputFactory from './DialogInputs'

const AbstractDialog = function (props) {
  const inputs = props.inputs || []
  const componentsRef = useMemo(
    () =>
      Array(inputs.length)
        .fill()
        .map(() => createRef()),
    [inputs.length]
  )
  useEffect(() => {
    setIsValids(componentsRef.map((ref) => ref.current?.isValid() || false))
    setIsValid(
      componentsRef.reduce(
        (isAllValid, ref) => isAllValid && (ref.current?.isValid() || false),
        true
      )
    )
  }, [inputs.length])
  const [isValid, setIsValid] = useState(false)
  const [isValids, setIsValids] = useState(Array(inputs.length).fill(false))
  const isTable = inputs.reduce(
    (flag, input) => input.type.endsWith('Table') || flag,
    false
  )
  const components = inputs.map((input, i) => {
    const MyComponent = inputFactory(input.type)
    return (
      <div
        style={{
          width: isTable && !input.type.endsWith('Table') ? '51%' : '100%',
        }}
      >
        <MyComponent
          {...input}
          index={i}
          setIsValid={setIsValid}
          isValids={isValids}
          setIsValids={setIsValids}
          body={props.body}
          setBody={props.setBody}
          ref={componentsRef[i]}
        />
      </div>
    )
  })
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      {...(isTable && { maxWidth: 'lg', fullWidth: true })}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <Divider />
      <DialogContent>
        <form
          action="/"
          method="POST"
          id="mainForm"
          onSubmit={() => props.onSubmit(props.body)}
        >
          <DialogContent>
            <Guard condition={props.info}>
              <DialogContentText>
                <p style={{ color: 'black' }}> {props.info}</p>
              </DialogContentText>
            </Guard>
            {components}
          </DialogContent>
          <Guard condition={props.confirm}>
            <DialogActions>
              <Button type="submit" color="secondary" variant="contained">
                {translate.Yes}
              </Button>
              <Button variant="contained" color="primary" onClick={props.close}>
                {translate.No}
              </Button>
            </DialogActions>
          </Guard>
          <Guard condition={props.withReset}>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  props.close()
                  props.resetFunction()
                }}
              >
                {translate.Reset}
              </Button>
              <Button
                disabled={!isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                {translate.Filter}
              </Button>
            </DialogActions>
          </Guard>
          <Guard condition={!props.confirm && !props.withReset}>
            <DialogActions>
              <Button
                disabled={!isValid}
                type="submit"
                color="primary"
                variant="contained"
              >
                {translate.Submit}
              </Button>
            </DialogActions>
          </Guard>
        </form>
      </DialogContent>
    </Dialog>
  )
}
export default AbstractDialog
