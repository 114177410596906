import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React, { useState } from 'react'

const ImageRender = function (props) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <img
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            src={`${
              !props.data.startsWith('http')
                ? process.env.REACT_APP_MEDIA_SERVER
                : ''
            }${props.data}`}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <img
        style={{ maxWidth: '20vw', maxHeight: '20vh' }}
        src={`${
          !props.data.startsWith('http')
            ? process.env.REACT_APP_MEDIA_SERVER
            : ''
        }${props.data}`}
        alt=""
        onClick={() => setOpen(true)}
      />
    </>
  )
}
export default ImageRender
