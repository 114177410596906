import { serialize } from 'object-to-formdata'

const arraysMatch = (arr1, arr2) => {
  if (arr1 === arr2) return true
  if (arr1 == null || arr2 == null) return false
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) if (arr1[i] !== arr2[i]) return false
  return true
}
const compare = (source, state) =>
  Object.keys(state).reduce((destination, key) => {
    if (key.startsWith('media') || key.endsWith('Picture'))
      return { ...destination, [key]: state[key] }
    if (Object.prototype.toString.call(state[key]) === '[object Object]') {
      const comparedObject = compare(source[key], state[key])
      return {
        ...destination,
        ...(Object.keys(comparedObject).length && { [key]: comparedObject }),
      }
    }
    if (
      Object.prototype.toString.call(state[key]) === '[object Array]' &&
      !arraysMatch(source[key], state[key])
    )
      destination[key] = state[key]
    if (`${state[key]}` !== `${source[key]}`) destination[key] = state[key]
    return destination
  }, {})
const getFormData = (object) => serialize(object)
const cleanEmpty = (obj) =>
  Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
    .reduce((a, [k, v]) => {
      if (v) a[k] = v
      return a
    }, {})
const omit = (object, keys) => {
  if (!keys.length) return object
  const { [keys.pop()]: omitted, ...rest } = object
  return omit(rest, keys)
}
const pick = (object, keys) =>
  keys.reduce((obj, key) => {
    if (
      object &&
      Object.prototype.hasOwnProperty.call(object, key) &&
      object[key]
    )
      obj[key] = object[key]
    return obj
  }, {})
const typify = (object) =>
  Object.keys(object).reduce((obj, key) => {
    const lowerCase = object[key].toLowerCase()
    const number = lowerCase.match(/^[+-]?[0-9]+(\.[0-9]+)?([eE][+-]?[0-9]+)?$/)
    if (number) obj[key] = +object[key]
    else if (lowerCase === 'true' || lowerCase === 'false')
      obj[key] = JSON.parse(lowerCase)
    else obj[key] = object[key]
    return obj
  }, {})
const flattenObject = (object, newObject = {}) => {
  Object.keys(object).forEach((key) => {
    if (
      object[key] &&
      !Array.isArray(object[key]) &&
      typeof object[key] === 'object'
    )
      flattenObject(object[key], newObject)
    else newObject[key] = object[key]
  })
  return newObject
}
export default {
  arraysMatch,
  compare,
  getFormData,
  cleanEmpty,
  omit,
  pick,
  typify,
  flattenObject,
}
