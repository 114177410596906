import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { videoService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Videos = function ({ showSlug, showName, type }) {
  const collectionName = 'Videos'
  const singleCollectionName = 'Video'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {
    ...(showSlug && { showSlug, type }),
  }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    videoService.query
  )
  const createMutation = reactQuery.createMutation(
    videoService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        ...(!type
          ? [
              {
                buttonLabel: translate.Filters,
                buttonColor: 'primary',
                onSubmit: (args) =>
                  setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    ...args,
                    query: collectionName,
                  }),
                body,
                setBody,
                withClear: true,
                clearFunction: () => setBody({}),
                withForm: true,
                form: {
                  title: `${translate.Filter} ${translate[singleCollectionName]}`,
                  inputs: [
                    {
                      name: 'type',
                      label: 'Type',
                      type: 'select',
                      value: queryParams.type,
                      options: ['', ...types.mediaTypes].map((mediaType) => ({
                        label: stringUtils.camelCase(mediaType),
                        value: mediaType,
                      })),
                    },
                    {
                      name: 'videoType',
                      label: 'Video Type',
                      type: 'select',
                      value: queryParams.videoType,
                      options: ['', ...types.videoTypes].map((videoType) => ({
                        label: stringUtils.camelCase(videoType),
                        value: videoType,
                      })),
                    },
                  ],
                },
                withReset: true,
                resetFunction: () => setSearchParams(),
              },
            ]
          : []),
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { thumbnailPicture, media } = args
            delete args.thumbnailPicture
            delete args.media
            const formData = objectUtils.getFormData({
              ...args,
              ...(type && { showSlug, showName, type }),
            })
            if (thumbnailPicture && thumbnailPicture.length)
              Object.keys(thumbnailPicture).forEach((key) =>
                formData.append('thumbnailPicture', thumbnailPicture[key])
              )
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('url', media[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'title',
                label: 'Title',
                type: 'text',
                required: true,
              },
              {
                name: 'titleAR',
                label: 'Arabic Title',
                type: 'text',
                required: true,
              },
              ...(!type
                ? [
                    {
                      name: 'type',
                      label: 'Type',
                      type: 'select',
                      options: types.mediaTypes.map((mediaType) => ({
                        label: stringUtils.camelCase(mediaType),
                        value: mediaType,
                      })),
                      required: true,
                    },
                  ]
                : []),
              {
                name: 'videoType',
                label: 'Video Type',
                type: 'select',
                value: body.videoType,
                options: types.videoTypes.map((videoType) => ({
                  label: stringUtils.camelCase(videoType),
                  value: videoType,
                })),
                required: true,
              },
              ...(body.videoType === 'audio'
                ? [
                    {
                      name: 'thumbnailPicture',
                      label: 'Thumbnail Picture',
                      type: 'file',
                      accept: 'image/*',
                      required: true,
                    },
                  ]
                : []),
              ...(body.videoType === 'audio' || body.videoType === 'video'
                ? [
                    {
                      name: 'media',
                      label: `${stringUtils.camelCase(body.videoType)} File`,
                      type: 'file',
                      accept: `${body.videoType}/*`,
                      required: true,
                    },
                  ]
                : []),
              ...(body.videoType === 'youtube'
                ? [
                    {
                      name: 'url',
                      label: 'Youtube URL',
                      type: 'text',
                      required: true,
                    },
                  ]
                : []),
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={['saved', ...(showSlug ? ['type', 'showName'] : [])]}
      navigate={showSlug ? [queryKey, '_id'] : null}
    />
  )
}
export default Videos
