import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Ads from '../Collections/Ads'
import ContactUss from '../Collections/ContactUss'
import Donations from '../Collections/Donations'
import Homes from '../Collections/Homes'
import Newss from '../Collections/Newss'
import Notifications from '../Collections/Notifications'
import Schedules from '../Collections/Schedules'
import Shows from '../Collections/Shows'
import Users from '../Collections/Users'
import Videos from '../Collections/Videos'
import Ad from '../Documents/Ad'
import Comment from '../Documents/Comment'
import ContactUs from '../Documents/ContactUs'
import Home from '../Documents/Home'
import News from '../Documents/News'
import Notification from '../Documents/Notification'
import Poll from '../Documents/Poll'
import Post from '../Documents/Post'
import Profile from '../Documents/Profile'
import Reply from '../Documents/Reply'
import Schedule from '../Documents/Schedule'
import Show from '../Documents/Show'
import User from '../Documents/User'
import UserOwner from '../Documents/UserOwner'
import Video from '../Documents/Video'
import RoleBasedGuard from '../Guards/RoleBasedGuard'

const MainRoutes = function () {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/homes" />} />
      <Route path="/ads" element={<Ads />} />
      <Route path="/ads/:id" element={<Ad />} />
      <Route path="/contactus" element={<ContactUss />} />
      <Route path="/contactus/:id" element={<ContactUs />} />
      <Route
        path="/donations"
        element={
          <RoleBasedGuard permitedRoles={['owner']}>
            <Donations />
          </RoleBasedGuard>
        }
      />
      <Route path="/homes" element={<Homes />} />
      <Route path="/homes/:id" element={<Home />} />
      <Route path="/news" element={<Newss />} />
      <Route path="/news/:id" element={<News />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/notifications/:id" element={<Notification />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/schedules" element={<Schedules />} />
      <Route path="/schedules/:id" element={<Schedule />} />
      <Route path="/shows" element={<Shows />} />
      <Route path="/shows/:id" element={<Show />} />
      <Route path="/posts/:id" element={<Post />} />
      <Route path="/posts/:id/poll/:optionId" element={<Poll />} />
      <Route path="/posts/:id/comments/:commentId" element={<Comment />} />
      <Route
        path="/posts/:id/comments/:commentId/replies/:replyId"
        element={<Reply />}
      />
      <Route
        path="/users"
        element={
          <RoleBasedGuard permitedRoles={['owner']}>
            <Users />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/users/:id"
        element={
          <RoleBasedGuard permitedRoles={['owner']}>
            <User />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/users/owner/:id"
        element={
          <RoleBasedGuard permitedRoles={['owner']}>
            <UserOwner />
          </RoleBasedGuard>
        }
      />
      <Route path="/videos" element={<Videos />} />
      <Route path="/videos/:id" element={<Video />} />
      <Route path="*" element={<Homes />} />
    </Routes>
  )
}
export default MainRoutes
