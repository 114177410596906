import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { userService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Profile = function () {
  const collectionName = 'Users'
  const singleCollectionName = 'User'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    undefined,
    userService.get
  )
  const updateMutation = reactQuery.updateMutation(
    userService.update,
    undefined,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => updateMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'password',
                label: 'Password',
                type: 'password',
                required: true,
              },
            ],
          },
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Profile
