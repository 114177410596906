import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { postService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Reply = function () {
  const singleCollectionName = 'Reply'
  const singleQueryKey = 'post'
  const params = useParams()
  const getQuery = reactQuery.subSubGetOneMutation(
    singleQueryKey,
    params.id,
    params.commentId,
    params.replyId,
    postService.getReply
  )
  const updateMutation = reactQuery.subSubUpdateMutation(
    postService.updateReply,
    params.id,
    params.commentId,
    params.replyId,
    singleCollectionName,
    singleQueryKey
  )
  const deleteMutation = reactQuery.subSubDeleteMutation(
    postService.deleteReply,
    params.id,
    params.commentId,
    params.replyId,
    singleCollectionName,
    singleQueryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => updateMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'reply',
                label: 'Reply',
                type: 'text',
                value: getQuery.data?.reply,
                required: true,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Reply
