import React, { useState } from 'react'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { scheduleService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Schedules = function ({
  showId,
  showSlug,
  showName,
  showNameAR,
  showCircleProfilePicture,
  showCoverPicture,
  showType,
}) {
  const collectionName = 'Schedules'
  const singleCollectionName = 'Schedule'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {
    ...(showId && { showId }),
  }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    scheduleService.query
  )
  const createMutation = reactQuery.createMutation(
    scheduleService.create,
    singleCollectionName,
    queryKey
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const daysNames = [
              'sunday',
              'monday',
              'tuesday',
              'wednesday',
              'thursday',
              'friday',
              'saturday',
            ]
            const daysValues = objectUtils.pick(args, daysNames)
            args = objectUtils.omit(args, [...daysNames])
            const days = []
            daysNames.forEach((daysName, i) =>
              daysValues[daysName] ? days.push(i) : null
            )
            const { coverPicture, start, end } = args
            delete args.coverPicture
            delete args.start
            delete args.end
            ;[args.startHour, args.startMinute] = start.split(':')
            ;[args.endHour, args.endMinute] = end.split(':')
            const formData = objectUtils.getFormData({
              ...args,
              days,
              ...(showId && {
                showId,
                showSlug,
                showName,
                showNameAR,
                showCircleProfilePicture,
                showCoverPicture,
                showType,
              }),
            })
            if (coverPicture && coverPicture.length)
              Object.keys(coverPicture).forEach((key) =>
                formData.append('coverPicture', coverPicture[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'sunday',
                label: 'Sunday',
                type: 'boolean',
              },
              {
                name: 'monday',
                label: 'Monday',
                type: 'boolean',
              },
              {
                name: 'tuesday',
                label: 'Tuesday',
                type: 'boolean',
              },
              {
                name: 'wednesday',
                label: 'Wednesday',
                type: 'boolean',
              },
              {
                name: 'thursday',
                label: 'Thursday',
                type: 'boolean',
              },
              {
                name: 'friday',
                label: 'Friday',
                type: 'boolean',
              },
              {
                name: 'saturday',
                label: 'Saturday',
                type: 'boolean',
              },
              {
                name: 'start',
                label: 'Start Time',
                type: 'time',
                required: true,
              },
              {
                name: 'end',
                label: 'End Time',
                type: 'time',
                required: true,
              },
              {
                name: 'firstRun',
                label: 'First Run',
                type: 'boolean',
                required: true,
              },
              ...(!showId
                ? [
                    {
                      name: 'name',
                      label: 'Name',
                      type: 'text',
                      required: true,
                    },
                    {
                      name: 'nameAR',
                      label: 'Arabic Name',
                      type: 'text',
                      required: true,
                    },
                    {
                      name: 'coverPicture',
                      label: 'Cover Picture',
                      type: 'file',
                      accept: 'image/*',
                      required: true,
                    },
                  ]
                : []),
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={[
        'startTotal',
        'endTotal',
        ...(showId
          ? [
              'showName',
              'showNameAR',
              'showCircleProfilePicture',
              'showCoverPicture',
              'showType',
            ]
          : []),
      ]}
      navigate={showId ? [queryKey, '_id'] : null}
    />
  )
}
export default Schedules
