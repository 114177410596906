import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { adService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Ad = function () {
  const collectionName = 'Ads'
  const singleCollectionName = 'Ad'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    adService.get
  )
  const updateMutation = reactQuery.updateMutation(
    adService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    adService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            const { circleProfilePicture, thumbnailPicture, media, mediaFile } =
              newBody
            delete newBody.circleProfilePicture
            delete newBody.thumbnailPicture
            delete newBody.media
            delete newBody.mediaFile
            const formData = objectUtils.getFormData(newBody)
            if (circleProfilePicture && circleProfilePicture.length)
              Object.keys(circleProfilePicture).forEach((key) =>
                formData.append(
                  'circleProfilePicture',
                  circleProfilePicture[key]
                )
              )
            if (thumbnailPicture && thumbnailPicture.length)
              Object.keys(thumbnailPicture).forEach((key) =>
                formData.append('thumbnailPicture', thumbnailPicture[key])
              )
            if (mediaFile && mediaFile.length)
              Object.keys(mediaFile).forEach((key) =>
                formData.append('url', mediaFile[key])
              )
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            if (
              Object.keys(newBody).length ||
              (circleProfilePicture && circleProfilePicture.length) ||
              (thumbnailPicture && thumbnailPicture.length) ||
              (mediaFile && mediaFile.length) ||
              (media && media.length)
            )
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                value: getQuery.data?.name,
              },
              {
                name: 'nameAR',
                label: 'Arabic Name',
                type: 'text',
                value: getQuery.data?.nameAR,
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'number',
                value: getQuery.data?.priority,
              },
              {
                name: 'circleProfilePicture',
                label: 'Circle Profile Picture',
                type: 'file',
                accept: 'image/*',
              },
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                value: getQuery.data?.description,
              },
              {
                name: 'descriptionAR',
                label: 'Arabic Description',
                type: 'text',
                value: getQuery.data?.descriptionAR,
              },
              ...(getQuery.data?.type === 'images'
                ? [
                    {
                      name: 'media',
                      label: 'Media',
                      type: 'file',
                      accept: 'image/*',
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'audio'
                ? [
                    {
                      name: 'thumbnailPicture',
                      label: 'Thumbnail Picture',
                      type: 'file',
                      accept: 'image/*',
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'audio' ||
              getQuery.data?.type === 'video'
                ? [
                    {
                      name: 'mediaFile',
                      label: `${stringUtils.camelCase(
                        getQuery.data?.type
                      )} File`,
                      type: 'file',
                      accept: `${getQuery.data?.type}/*`,
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'youtube'
                ? [
                    {
                      name: 'url',
                      label: 'Youtube URL',
                      type: 'text',
                      value: getQuery.data?.url,
                    },
                  ]
                : []),
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Ad
