import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { postService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Comment = function () {
  const singleCollectionName = 'Comment'
  const singleQueryKey = 'post'
  const params = useParams()
  const getQuery = reactQuery.subGetOneMutation(
    singleQueryKey,
    params.id,
    params.commentId,
    postService.getComment
  )
  const updateMutation = reactQuery.subUpdateMutation(
    postService.updateComment,
    params.id,
    params.commentId,
    singleCollectionName,
    singleQueryKey
  )
  const deleteMutation = reactQuery.subDeleteMutation(
    postService.deleteComment,
    params.id,
    params.commentId,
    singleCollectionName,
    singleQueryKey
  )
  const addReplyMutation = reactQuery.subSubCreateMutation(
    postService.createReply,
    params.id,
    params.commentId,
    'Reply',
    singleQueryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => updateMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'comment',
                label: 'Comment',
                type: 'text',
                value: getQuery.data?.comment,
                required: true,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
        {
          buttonLabel: `${translate.Add} ${translate.Reply}`,
          buttonColor: 'primary',
          onSubmit: (args) => addReplyMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate.Reply}`,
            inputs: [
              {
                name: 'reply',
                label: 'Reply',
                type: 'text',
                required: true,
              },
            ],
          },
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Comment
