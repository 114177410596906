import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import dateUtils from '../../_helpers/dateUtils'
import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { postService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Post = function () {
  const collectionName = 'Posts'
  const singleCollectionName = 'Post'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    postService.get
  )
  const updateMutation = reactQuery.updateMutation(
    postService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    postService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  const addPollOptionMutation = reactQuery.subCreateMutation(
    postService.createPollOption,
    params.id,
    'Option',
    singleQueryKey
  )
  const addCommentMutation = reactQuery.subCreateMutation(
    postService.createComment,
    params.id,
    'Comment',
    singleQueryKey
  )
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            const { thumbnailPicture, media, mediaFile } = newBody
            delete newBody.thumbnailPicture
            delete newBody.media
            delete newBody.mediaFile
            const formData = objectUtils.getFormData(newBody)
            if (thumbnailPicture && thumbnailPicture.length)
              Object.keys(thumbnailPicture).forEach((key) =>
                formData.append('thumbnailPicture', thumbnailPicture[key])
              )
            if (mediaFile && mediaFile.length)
              Object.keys(mediaFile).forEach((key) =>
                formData.append('url', mediaFile[key])
              )
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            if (
              Object.keys(newBody).length ||
              (thumbnailPicture && thumbnailPicture.length) ||
              (mediaFile && mediaFile.length) ||
              (media && media.length)
            )
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                value: getQuery.data?.description,
              },
              {
                name: 'descriptionAR',
                label: 'Arabic Description',
                type: 'text',
                value: getQuery.data?.descriptionAR,
              },
              ...(getQuery.data?.type === 'images'
                ? [
                    {
                      name: 'media',
                      label: 'Media',
                      type: 'file',
                      accept: 'image/*',
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'audio'
                ? [
                    {
                      name: 'thumbnailPicture',
                      label: 'Thumbnail Picture',
                      type: 'file',
                      accept: 'image/*',
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'audio' ||
              getQuery.data?.type === 'video'
                ? [
                    {
                      name: 'mediaFile',
                      label: `${stringUtils.camelCase(
                        getQuery.data?.type
                      )} File`,
                      type: 'file',
                      accept: `${getQuery.data?.type}/*`,
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'youtube'
                ? [
                    {
                      name: 'url',
                      label: 'Youtube URL',
                      type: 'text',
                      value: getQuery.data?.url,
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'poll'
                ? [
                    {
                      name: 'pollDeadline',
                      label: 'Poll Deadline Date',
                      type: 'date',
                      value: dateUtils.formatDatePicker(
                        getQuery.data?.pollDeadline
                      ),
                    },
                  ]
                : []),
              ...(getQuery.data?.type === 'donation'
                ? [
                    ...(getQuery.data?.media.length
                      ? [
                          {
                            name: 'media',
                            label: 'Media',
                            type: 'file',
                            accept: 'image/*',
                          },
                        ]
                      : []),
                    ...(getQuery.data?.url
                      ? [
                          getQuery.data?.url.includes('youtube')
                            ? {
                                name: 'url',
                                label: 'Youtube URL',
                                type: 'text',
                                value: getQuery.data?.url,
                              }
                            : {
                                name: 'mediaFile',
                                label: `Video File`,
                                type: 'file',
                                accept: `video/*`,
                              },
                        ]
                      : []),
                    {
                      name: 'donationGoal',
                      label: 'Donation Goal',
                      type: 'number',
                      value: getQuery.data?.donationGoal,
                    },
                  ]
                : []),
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
        ...(getQuery.data?.type === 'poll'
          ? [
              {
                buttonLabel: `${translate.Add} ${translate.Option}`,
                buttonColor: 'primary',
                onSubmit: (args) => addPollOptionMutation.mutate(args),
                body,
                setBody,
                withClear: true,
                clearFunction: () => setBody({}),
                form: {
                  title: `${translate.Add} ${translate.Option}`,
                  inputs: [
                    {
                      name: 'option',
                      label: 'Option',
                      type: 'text',
                      required: true,
                    },
                    {
                      name: 'optionAR',
                      label: 'Arabic Option',
                      type: 'text',
                      required: true,
                    },
                  ],
                },
              },
            ]
          : []),
        {
          buttonLabel: `${translate.Add} ${translate.Comment}`,
          buttonColor: 'primary',
          onSubmit: (args) => addCommentMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate.Comment}`,
            inputs: [
              {
                name: 'comment',
                label: 'Comment',
                type: 'text',
                required: true,
              },
            ],
          },
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
      notToShow={['users']}
    />
  )
}
export default Post
