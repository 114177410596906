import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { videoService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Video = function () {
  const collectionName = 'Videos'
  const singleCollectionName = 'Video'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    videoService.get
  )
  const updateMutation = reactQuery.updateMutation(
    videoService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    videoService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            const { thumbnailPicture, media } = newBody
            delete newBody.thumbnailPicture
            delete newBody.media
            const formData = objectUtils.getFormData(newBody)
            if (thumbnailPicture && thumbnailPicture.length)
              Object.keys(thumbnailPicture).forEach((key) =>
                formData.append('thumbnailPicture', thumbnailPicture[key])
              )
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('url', media[key])
              )
            if (
              Object.keys(newBody).length ||
              (thumbnailPicture && thumbnailPicture.length) ||
              (media && media.length)
            )
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'title',
                label: 'Title',
                type: 'text',
                value: getQuery.data.title,
              },
              {
                name: 'titleAR',
                label: 'Arabic Title',
                type: 'text',
                value: getQuery.data.titleAR,
              },
              ...(getQuery.data.type !== 'episode'
                ? [
                    {
                      name: 'type',
                      label: 'Type',
                      type: 'select',
                      options: types.mediaTypes.map((mediaType) => ({
                        label: stringUtils.camelCase(mediaType),
                        value: mediaType,
                      })),
                      value: getQuery.data.type,
                    },
                  ]
                : []),
              ...(getQuery.data.videoType === 'audio'
                ? [
                    {
                      name: 'thumbnailPicture',
                      label: 'Thumbnail Picture',
                      type: 'file',
                      accept: 'image/*',
                    },
                  ]
                : []),
              ...(getQuery.data.videoType === 'audio' ||
              getQuery.data.videoType === 'video'
                ? [
                    {
                      name: 'media',
                      label: `${stringUtils.camelCase(
                        getQuery.data.videoType
                      )} File`,
                      type: 'file',
                      accept: `${getQuery.data.videoType}/*`,
                    },
                  ]
                : []),
              ...(getQuery.data.videoType === 'youtube'
                ? [
                    {
                      name: 'url',
                      label: 'Youtube URL',
                      type: 'text',
                      value: getQuery.data.url,
                    },
                  ]
                : []),
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Video
