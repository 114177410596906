import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { userService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'
import Donations from '../Collections/Donations'

const UserOwner = function () {
  const singleCollectionName = 'User'
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    userService.getById
  )
  const navigate = useNavigate()
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <>
      <Document
        actions={[
          {
            buttonLabel: `${translate.Manage} ${translate[singleCollectionName]}`,
            buttonColor: 'secondary',
            onSubmit: () => navigate(`/users/${getQuery.data.email}`),
            form: {
              title: `${translate.Manage} ${translate[singleCollectionName]}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to manage this']} ${translate[singleCollectionName]}`,
          },
        ]}
        title={singleCollectionName}
        getQuery={getQuery}
        many={false}
      />
      <Donations userId={getQuery.data._id} />
    </>
  )
}
export default UserOwner
