const availableKeys = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  ACCESS_TOKEN_EXPIRY: 'ACCESS_TOKEN_EXPIRY',
}
const set = (key, value) => localStorage.setItem(key, value)
const get = (key) => localStorage.getItem(key)
const clear = () => localStorage.clear()
const exists = (key) => !!localStorage.getItem(key)
export default {
  availableKeys,
  set,
  get,
  clear,
  exists,
}
