import React from 'react'
import ReactPlayer from 'react-player/lazy'

const VideoRender = function (props) {
  return (
    <ReactPlayer
      playing
      controls
      loop
      light={
        !props.data.startsWith('http')
          ? `${process.env.REACT_APP_MEDIA_SERVER}video/upload/${props.data}.jpg`
          : true
      }
      url={
        props.data.startsWith('http')
          ? props.data
          : `${process.env.REACT_APP_MEDIA_SERVER}video/upload/${props.data}`
      }
      style={{ maxWidth: '20vw', maxHeight: '20vh' }}
    />
  )
}
export default VideoRender
