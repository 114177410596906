import TextField from '@mui/material/TextField'
import React from 'react'

import translate from '../../../locales/en'

function Text(props) {
  return (
    <TextField
      margin="normal"
      label={translate[props.label]}
      type="text"
      fullWidth
      InputLabelProps={{ shrink: true }}
      key={`${props.index}TextField`}
      autoFocus={props.index === 0}
      value={props.value || ''}
      onChange={(event) => props.onChange(event.target.value)}
      required={props.required}
      error={props.required && !(props.value || '').length}
      {...(props.required &&
        !(props.value || '').length && {
          helperText: `${translate[props.label]} ${translate['is required']}`,
        })}
    />
  )
}

export default Text
