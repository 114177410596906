import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { scheduleService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Schedule = function () {
  const collectionName = 'Schedules'
  const singleCollectionName = 'Schedule'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    scheduleService.get
  )
  const updateMutation = reactQuery.updateMutation(
    scheduleService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    scheduleService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const daysNames = [
              'sunday',
              'monday',
              'tuesday',
              'wednesday',
              'thursday',
              'friday',
              'saturday',
            ]
            const daysValues = objectUtils.pick(args, daysNames)
            args = objectUtils.omit(args, [...daysNames])
            const days = []
            daysNames.forEach((daysName, i) =>
              daysValues[daysName] ? days.push(i) : null
            )
            args.days = days
            const { start, end } = args
            delete args.start
            delete args.end
            if (start) {
              ;[args.startHour, args.startMinute] = start.split(':')
              args.startHour = +args.startHour
              args.startMinute = +args.startMinute
            }
            if (end) {
              ;[args.endHour, args.endMinute] = end.split(':')
              args.endHour = +args.endHour
              args.endMinute = +args.endMinute
            }
            const newBody = objectUtils.compare(getQuery.data, args)
            const { coverPicture } = newBody
            delete newBody.coverPicture
            const formData = objectUtils.getFormData(newBody)
            if (coverPicture && coverPicture.length)
              Object.keys(coverPicture).forEach((key) =>
                formData.append('coverPicture', coverPicture[key])
              )
            if (
              Object.keys(newBody).length ||
              (coverPicture && coverPicture.length)
            )
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'sunday',
                label: 'Sunday',
                type: 'boolean',
                value: getQuery.data?.days.includes(0),
              },
              {
                name: 'monday',
                label: 'Monday',
                type: 'boolean',
                value: getQuery.data?.days.includes(1),
              },
              {
                name: 'tuesday',
                label: 'Tuesday',
                type: 'boolean',
                value: getQuery.data?.days.includes(2),
              },
              {
                name: 'wednesday',
                label: 'Wednesday',
                type: 'boolean',
                value: getQuery.data?.days.includes(3),
              },
              {
                name: 'thursday',
                label: 'Thursday',
                type: 'boolean',
                value: getQuery.data?.days.includes(4),
              },
              {
                name: 'friday',
                label: 'Friday',
                type: 'boolean',
                value: getQuery.data?.days.includes(5),
              },
              {
                name: 'saturday',
                label: 'Saturday',
                type: 'boolean',
                value: getQuery.data?.days.includes(6),
              },
              {
                name: 'start',
                label: 'Start Time',
                type: 'time',
                value:
                  getQuery.data?.startHour >= 0
                    ? `${stringUtils.twoDigit(
                        getQuery.data.startHour
                      )}:${stringUtils.twoDigit(getQuery.data.startMinute)}`
                    : '',
              },
              {
                name: 'end',
                label: 'End Time',
                type: 'time',
                value:
                  getQuery.data?.endHour >= 0
                    ? `${stringUtils.twoDigit(
                        getQuery.data.endHour
                      )}:${stringUtils.twoDigit(getQuery.data.endMinute)}`
                    : '',
              },
              {
                name: 'firstRun',
                label: 'First Run',
                type: 'boolean',
                value: getQuery.data?.firstRun,
              },
              ...(!getQuery.data?.showId
                ? [
                    {
                      name: 'name',
                      label: 'Name',
                      type: 'text',
                      value: getQuery.data?.name,
                    },
                    {
                      name: 'nameAR',
                      label: 'Arabic Name',
                      type: 'text',
                      value: getQuery.data?.nameAR,
                    },
                    {
                      name: 'coverPicture',
                      label: 'Cover Picture',
                      type: 'file',
                      accept: 'image/*',
                    },
                  ]
                : []),
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
      notToShow={['startTotal', 'endTotal']}
    />
  )
}
export default Schedule
