const formatSeconds = (seconds) =>
  new Date(seconds * 1000).toISOString().substring(11, 19)
const formatDate = (date) =>
  date ? new Date(date).toLocaleDateString('en-EG') : ''
const formatDateTime = (date) =>
  date
    ? `${formatDate(date)} ${new Date(date).toLocaleTimeString('en-EG')}`
    : ''
const formatDatePicker = (date) =>
  date ? new Date(date).toLocaleDateString('en-CA') : ''
export default {
  formatSeconds,
  formatDate,
  formatDateTime,
  formatDatePicker,
}
