import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { adService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Ads = function () {
  const collectionName = 'Ads'
  const singleCollectionName = 'Ad'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, adService.query)
  const createMutation = reactQuery.createMutation(
    adService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                value: queryParams.type,
                options: types.adTypes.map((adType) => ({
                  label: stringUtils.camelCase(adType),
                  value: adType,
                })),
                required: true,
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { circleProfilePicture, thumbnailPicture, media, mediaFile } =
              args
            delete args.circleProfilePicture
            delete args.thumbnailPicture
            delete args.media
            delete args.mediaFile
            const formData = objectUtils.getFormData(args)
            Object.keys(circleProfilePicture).forEach((key) =>
              formData.append('circleProfilePicture', circleProfilePicture[key])
            )
            if (thumbnailPicture && thumbnailPicture.length)
              Object.keys(thumbnailPicture).forEach((key) =>
                formData.append('thumbnailPicture', thumbnailPicture[key])
              )
            if (mediaFile && mediaFile.length)
              Object.keys(mediaFile).forEach((key) =>
                formData.append('url', mediaFile[key])
              )
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'nameAR',
                label: 'Arabic Name',
                type: 'text',
                required: true,
              },
              {
                name: 'circleProfilePicture',
                label: 'Circle Profile Picture',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'number',
                required: true,
              },
              {
                name: 'description',
                label: 'Arabic Description',
                type: 'text',
                required: true,
              },
              {
                name: 'descriptionAR',
                label: 'Description',
                type: 'text',
                required: true,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                value: body.type,
                options: types.adTypes.map((adType) => ({
                  label: stringUtils.camelCase(adType),
                  value: adType,
                })),
                required: true,
              },
              ...(body.type === 'images'
                ? [
                    {
                      name: 'media',
                      label: 'Media',
                      type: 'file',
                      accept: 'image/*',
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'audio'
                ? [
                    {
                      name: 'thumbnailPicture',
                      label: 'Thumbnail Picture',
                      type: 'file',
                      accept: 'image/*',
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'audio' || body.type === 'video'
                ? [
                    {
                      name: 'mediaFile',
                      label: `${stringUtils.camelCase(body.type)} File`,
                      type: 'file',
                      accept: `${body.type}/*`,
                      required: true,
                    },
                  ]
                : []),
              ...(body.type === 'youtube'
                ? [
                    {
                      name: 'url',
                      label: 'Youtube URL',
                      type: 'text',
                      required: true,
                    },
                  ]
                : []),
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Ads
