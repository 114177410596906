const userTypes = ['owner', 'admin', 'editor']
const mediaTypes = [
  'hymn',
  'sermon',
  'documentary',
  'story',
  'eulogy',
  'choral',
]
const postTypes = [
  'images',
  'audio',
  'video',
  'youtube',
  'poll',
  'donation',
  'text',
]
const adTypes = ['images', 'audio', 'video', 'youtube', 'text']
const videoTypes = ['audio', 'video', 'youtube']
const currencyTypes = ['EGP', 'USD', 'CAD', 'AUD', 'EUR', 'GBP', 'AED']
const donationTypes = ['images', 'video', 'youtube']
const showsTypes = ['live', 'recorded', 'archived']
const newsTypes = ['breaking', 'general', 'church']
export default {
  userTypes,
  mediaTypes,
  postTypes,
  adTypes,
  videoTypes,
  currencyTypes,
  donationTypes,
  showsTypes,
  newsTypes,
}
